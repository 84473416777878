import Navbar from "../../components/Navbar";
import { Table, Button } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Axios from "axios";
import * as FaIcons from "react-icons/fa";

export default function MailListPage() {
    const [mailLists, setMailLists] = useState([]);

    const fetchMailLists = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/mail-lists`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const mailLists = data;
        setMailLists(mailLists);
    };

    const handleUsersModal = (id) => {

    }

    useEffect(() => {
        fetchMailLists();
    }, []);

    return (
        <>
            <Navbar />
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-row text-dark fw-bold my-0 fs-1">
                                            Mail list
                                        </h1>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="table-responsive">
                                            <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                <thead className="fw-bold text-muted">
                                                    <tr>
                                                        <th className="sorting">Name</th>
                                                        <th className="sorting">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        mailLists.map((item, index) => {
                                                            return (
                                                                <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                    <td className="min-w-150px">
                                                                        <div className="mb-2">{item.name}</div>
                                                                    </td>
                                                                    <td className="align-items-center">
                                                                        <Button variant="secondary" className="btn-icon btn-sm w-25px h-25px me-1" onClick={() => handleUsersModal(item.id)}>
                                                                            <FaIcons.FaUsers style={{ color: '#fff' }} />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}