import { Button, Form } from 'react-bootstrap';
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ChangePasswordPage() {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const myOnSubmit = data => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.patch(`${apiUrl}/api/users/change-password`, {
            password: data.password
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('User successfully changed password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            localStorage.removeItem('token');
            navigate("/login");
        }).catch(function (error) {
            toast.error(error.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        });
    }

    useEffect(() => {
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-row text-dark fw-bold my-0 fs-1">
                                            Change password
                                        </h1>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <Form noValidate onSubmit={handleSubmit(myOnSubmit)} id="changePassword">
                                        <Form.Group className="mb-4" controlId="changePassword.password">
                                            <Form.Label>Password</Form.Label>
                                            <input
                                                type="password"
                                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="password"
                                                {...register("password", {
                                                    required: "Field is required"
                                                })}
                                            />
                                            {errors.password && <div className="d-grid gap-2 mt-3 error">{errors.password.message}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="changePassword.surname">
                                            <Form.Label>Repeat password</Form.Label>
                                            <input
                                                type="password"
                                                className={`form-control ${errors.repeat_password ? 'is-invalid' : ''}`}
                                                placeholder="repeat password"
                                                {...register("repeat_password", {
                                                    required: "Field is required",
                                                    validate: (val) => {
                                                        if (watch('password') !== val) {
                                                            return "Your passwords do no match";
                                                        }
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                                                        message: "Password requirements: 8-20 characters, 1 number, 1 letter, 1 symbol."
                                                    }
                                                })}
                                            />
                                            {errors.repeat_password && <div className="d-grid gap-2 mt-3 error">{errors.repeat_password.message}</div>}
                                        </Form.Group>
                                        <Button type="submit" variant="primary" form="changePassword">
                                            Save
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}