import * as FaIcons from "react-icons/fa";
import { Table, Button } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Axios from "axios";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function IncubatorsPage() {
    const [incubators, setIncubators] = useState([]);

    const fetchIncubators = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/incubators`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const incubators = data;
        setIncubators(incubators);
    };

    const fetchFile = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        Axios({
            url: `${apiUrl}/api/incubators/download`,
            method: "GET",
            responseType: "blob" // important
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `incubators.csv`
            );
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    useEffect(() => {
        fetchIncubators();
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">
                                            Incubators
                                        </h1>
                                    </div>
                                    <div className="d-flex flex-column align-items-end justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <Button variant="secondary" className="btn-icon btn-md w-35px h-35px" onClick={fetchFile}>
                                            <FaIcons.FaFileCsv />
                                        </Button>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="table-responsive">
                                            <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                <thead className="fw-bold text-muted">
                                                    <tr>
                                                        <th className="sorting">Full title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        incubators.map((item, index) => {
                                                            return (
                                                                <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                    <td className="min-w-150px">
                                                                        <div className="mb-2">{item.fullTitle}</div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}