import 'bootstrap/dist/css/bootstrap.css';
import { useState, useEffect } from "react";
import Axios from "axios";
import Navbar from "../../components/Navbar";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function LoginPage() {
    const [ambassadors, setAmbassadors] = useState([]);
    const [evaluators, setEvaluators] = useState([]);
    const [incubators, setIncubators] = useState([]);
    const [boosters, setBoosters] = useState([]);
    const [expertiseAreas, setExpertiseAreas] = useState([]);
    const labelsAmbassadors = ambassadors.map(x => x.month);
    const labelsExpertiseAreas = expertiseAreas.map(x => x.name);

    const optionsAmbassadors = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };

    const dataAmbassadors = {
        labels: labelsAmbassadors,
        datasets: [
            {
                data: ambassadors.map(x => x.total),
                backgroundColor: '#1a83ff',
            }
        ],
    };

    const optionsExpertiseAreas = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };

    const dataExpertiseAreas = {
        labels: labelsExpertiseAreas,
        datasets: [
            {
                data: expertiseAreas.map(x => x.total),
                backgroundColor: '#9ad3e5',
            }
        ],
    };

    const fetchDashboard = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/application/dashboard`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const ambassadors = data.ambassadorsByMonth;
        const expertiseAreas = data.expertiseAreasMostUsed;
        const evaluators = data.evaluatorsByMonth;
        const incubators = data.incubatorsByMonth;
        const boosters = data.boostersByMonth;
        setAmbassadors(ambassadors);
        setExpertiseAreas(expertiseAreas);
        setEvaluators(evaluators);
        setIncubators(incubators);
        setBoosters(boosters);
    };

    useEffect(() => {
        fetchDashboard();
    }, []);

    return (
        <>
            <Navbar />
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                <h1 className="d-flex flex-row text-dark fw-bold my-0 fs-1">
                                    Home
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='row g-5 g-lg-10 mt-4'>
                        <div className='col-xl-8'>
                            <div className="row">
                                <div className="col-xxl-6 mb-5 mb-xl-10">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-5">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Ambassadors by month</span>
                                            </h3>
                                        </div>
                                        <div className="card-body pt-6">
                                            <Bar options={optionsAmbassadors} data={dataAmbassadors} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 mb-5 mb-xl-10">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-5">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Most used areas of expertise</span>
                                            </h3>
                                        </div>
                                        <div className="card-body pt-6">
                                            <Bar options={optionsExpertiseAreas} data={dataExpertiseAreas} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 order-1">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-6 mb-4">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-5">
                                            <h4 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Incubators in {incubators.length > 0 ? incubators[incubators.length - 1].month : ""}</span>
                                            </h4>
                                        </div>
                                        <div className="card-body pt-6">
                                            <div className='display-3'>{incubators.length > 0 ? incubators[incubators.length - 1].total : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-6 mb-4">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-5">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Evaluators in {evaluators.length > 0 ? evaluators[evaluators.length - 1].month : ""}</span>
                                            </h3>
                                        </div>
                                        <div className="card-body pt-6">
                                            <div className='display-3'>{evaluators.length > 0 ? evaluators[evaluators.length - 1].total : ""}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-6 mb-4">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-5">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Boosters in {boosters.length > 0 ? boosters[boosters.length - 1].month : ""}</span>
                                            </h3>
                                        </div>
                                        <div className="card-body pt-6">
                                            <div className='display-3'>{boosters.length > 0 ? boosters[boosters.length - 1].total : ""}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}