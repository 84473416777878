import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import { Table, Button, Modal, Badge } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AmbassadorsPage() {
    const [deleteId, setDeleteId] = useState("");
    const [ambassadors, setAmbassadors] = useState([]);
    const [showDelete, setShowDelete] = useState(false);

    const handleCloseDeleteModal = () => setShowDelete(false);
    const handleShowDeleteModal = () => setShowDelete(true);

    const handleClickDeleteModal = (id) => {
        setDeleteId(id);
        handleShowDeleteModal();
    }

    const handleDeleteClick = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.delete(`${apiUrl}/api/ambassadors/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Ambassador successfully deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchAmbassadors();
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setDeleteId("");
            handleCloseDeleteModal();
        });
    }

    const handleAcceptClick = (id) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.patch(`${apiUrl}/api/ambassadors/${id}/accept`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Ambassador successfully accepted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchAmbassadors();
        }).catch(function (error) {
            console.log(error);
        });
    }

    const fetchAmbassadors = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/ambassadors`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const ambassadors = data;
        setAmbassadors(ambassadors);
    };

    const fetchFile = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        Axios({
            url: `${apiUrl}/api/ambassadors/download`,
            method: "GET",
            responseType: "blob" // important
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `ambassadors.csv`
            );
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    useEffect(() => {
        fetchAmbassadors();
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <Modal show={showDelete} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete user</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this ambassador?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">
                                            Ambassadors
                                        </h1>
                                    </div>
                                    <div className="d-flex flex-column align-items-end justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <Button variant="secondary" className="btn-icon btn-md w-35px h-35px" onClick={fetchFile}>
                                            <FaIcons.FaFileCsv />
                                        </Button>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="table-responsive">
                                            <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                <thead className="fw-bold text-muted">
                                                    <tr>
                                                        <th className="sorting">Institution</th>
                                                        <th className="sorting">Email</th>
                                                        <th className="sorting">City</th>
                                                        <th className="sorting">Country</th>
                                                        <th className="sorting">Lat / Long</th>
                                                        <th className="sorting">Status</th>
                                                        <th className="sorting">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ambassadors.map((item, index) => {
                                                            return (
                                                                <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                    <td className="min-w-150px">
                                                                        <div className="mb-2">{item.institution}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        <div className="mb-2 fw-bold">{item.email}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        <div className="mb-2">{item.city}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        <div className="mb-2">{item.country}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        <div className="mb-2">{item.latitude} / {item.longitude}</div>
                                                                    </td>
                                                                    <td className="min-w-175px">
                                                                        <div className="mb-2">{
                                                                            item.isAccepted ?
                                                                                <Badge text="light" bg="success">Accepted</Badge>
                                                                                :
                                                                                <Badge text="light" bg="warning">Pending</Badge>
                                                                        }</div>
                                                                    </td>
                                                                    <td className="align-items-center">
                                                                        {
                                                                            !item.isAccepted ?
                                                                                <Button variant="success" className="btn-icon btn-sm w-25px h-25px me-1" onClick={() => handleAcceptClick(item.id)}>
                                                                                    <MdIcons.MdCheck style={{ color: '#fff' }} />
                                                                                </Button>
                                                                                :
                                                                                ''
                                                                        }
                                                                        <Button variant="danger" className="btn-icon btn-sm w-25px h-25px" onClick={() => handleClickDeleteModal(item.id)}>
                                                                            <MdIcons.MdDeleteForever style={{ color: '#fff' }} />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}