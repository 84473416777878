import { Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const RouteGuard = () => {

    function hasJwt() {
        const token = localStorage.getItem("token");
        if (!token) {
            return false;
        }
        let decodedToken = jwtDecode(token);
        let currentDate = new Date();

        // JWT exp is in seconds
        if (!decodedToken.exp || (decodedToken.exp && decodedToken.exp * 1000 < currentDate.getTime())) {
            localStorage.removeItem('token');
            return false;
        } else {
            return true;
        }
    }

    let auth = hasJwt();
    return (
        auth ? <Outlet /> : <Navigate to="/login" />
    );
};

export default RouteGuard;