import * as MdIcons from "react-icons/md";
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ExpertiseAreasPage() {
    const [deleteId, setDeleteId] = useState("");
    const [updateExpertiseArea, setUpdateExpertiseArea] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [expertiseAreas, setExpertiseAreas] = useState([]);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const createOnSubmit = data => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.post(`${apiUrl}/api/expertise-areas`, {
            name: data.name
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Area of expertise successfully created', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchExpertiseAreas();
            handleCloseCreateModal();
        }).catch(function (error) {
            toast.error(error.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        });
    }

    const updateOnSubmit = data => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.put(`${apiUrl}/api/expertise-areas/${updateExpertiseArea.id}`, {
            id: updateExpertiseArea.id,
            name: data.name
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Area of expertise successfully updated', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchExpertiseAreas();
            setUpdateExpertiseArea("");
            handleCloseUpdateModal();
        }).catch(function (error) {
            toast.error(error.data, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        });
    }
    
    const handleCloseDeleteModal = () => setShowDelete(false);
    const handleShowDeleteModal = () => setShowDelete(true);
    const handleCloseUpdateModal = () => setShowUpdate(false);
    const handleShowUpdateModal = () => setShowUpdate(true);
    const handleCloseCreateModal = () => {
        setShowCreate(false);
        reset();
    }
    const handleShowCreateModal = () => setShowCreate(true);

    const handleClickDeleteModal = (id) => {
        setDeleteId(id);
        handleShowDeleteModal();
    }

    const handleClickUpdateModal = async (id) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/expertise-areas/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const expertiseAreaUpdate = data;
        setUpdateExpertiseArea(expertiseAreaUpdate);
        setValue('name', expertiseAreaUpdate.name);
        handleShowUpdateModal();
    }

    const handleDeleteClick = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        Axios.delete(`${apiUrl}/api/expertise-areas/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async () => {
            toast.success('Area of expertise successfully deleted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            await fetchExpertiseAreas();
        }).catch(function (error) {
            console.log(error);
        }).finally(() => {
            setDeleteId("");
            handleCloseDeleteModal();
        });
    }

    const handleClickCreate = () => {
        setValue('name', '');
        handleShowCreateModal();
    }

    const fetchExpertiseAreas = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem("token");
        const { data } = await Axios.get(
            `${apiUrl}/api/expertise-areas`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const expertiseAreas = data;
        setExpertiseAreas(expertiseAreas);
    };

    useEffect(() => {
        fetchExpertiseAreas();
    }, []);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <Modal show={showCreate} onHide={handleCloseCreateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create area of expertise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSubmit(createOnSubmit)} id="createForm">
                        <Form.Group className="mb-4" controlId="createExpertiseArea.name">
                            <Form.Label>Name</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                placeholder="name"
                                {...register("name", {
                                    required: "Field is required"
                                })}
                            />
                            {errors.name && <div className="d-grid gap-2 mt-3 error">{errors.name.message}</div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreateModal}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" form="createForm">
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdate} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update area of expertise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSubmit(updateOnSubmit)} id="updateForm">
                        <Form.Group className="mb-4" controlId="updateExpertiseArea.name">
                            <Form.Label>Name</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                placeholder="name"
                                {...register("name", {
                                    required: "Field is required"
                                })}
                            />
                            {errors.name && <div className="d-grid gap-2 mt-3 error">{errors.name.message}</div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" form="updateForm">
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDelete} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete area of expertise</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this area of expertise?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDeleteClick}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="content d-flex flex-column flex-column-fluid">
                <div className=' container-fluid '>
                    <div className='row g-5 g-lg-10'>
                        <div className='col'>
                            <div className="card card-flush h-xl-100">
                                <div className="card-header">
                                    <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-2 pb-5 pb-lg-0 pt-7 pt-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                        <h1 className="d-flex flex-row text-dark fw-bold my-0 fs-1">
                                            Areas of expertise <Button className="btn-sm ms-4" variant="primary" onClick={() => handleClickCreate()}>
                                                Add <MdIcons.MdAdd style={{ color: '#fff' }} />
                                            </Button>
                                        </h1>
                                    </div>
                                </div>
                                <div className="card-body pt-1">
                                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="table-responsive">
                                            <Table className="table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                                                <thead className="fw-bold text-muted">
                                                    <tr>
                                                        <th className="sorting">Name</th>
                                                        <th className="sorting">Action</th></tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        expertiseAreas.map((item, index) => {
                                                            return (
                                                                <tr key={index} className={index % 2 === 0 ? 'odd' : 'even'}>
                                                                    <td className="min-w-150px">
                                                                        <div className="mb-2">{item.name}</div>
                                                                    </td>
                                                                    <td className="align-items-center">
                                                                        <Button variant="secondary" className="btn-icon btn-sm w-25px h-25px me-1" onClick={() => handleClickUpdateModal(item.id)}>
                                                                            <MdIcons.MdUpdate />
                                                                        </Button>
                                                                        <Button variant="danger" className="btn-icon btn-sm w-25px h-25px" onClick={() => handleClickDeleteModal(item.id)}>
                                                                            <MdIcons.MdDeleteForever style={{ color: '#fff' }} />
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}